<template>
  <div class="user">
    <div class="container">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="所在地区:" prop="">
                <el-select
                  v-model="queryInfo.province"
                  placeholder="请选择省份"
                  @change="citychange"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="queryInfo.city" placeholder="请选择市" @change="districtchange">
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="queryInfo.district" placeholder="请选择区" @change="search">
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in districtList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="伙伴类型:" prop="">
                <el-radio-group v-model="queryInfo.partnerType" @change="changeSearchangeSearch">
                  <el-radio-button label="" :value="''">全部</el-radio-button>
                  <el-radio-button
                    :label="item.codeName"
                    v-for="(item, index) in InformationTypes"
                    :key="index"
                    >{{ item.codeName }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="伙伴类别:">
                <el-radio-group v-model="queryInfo.directLeague" @change="changeSearchangeSearch">
                  <el-radio-button label="" :value="''">全部</el-radio-button>
                  <el-radio-button
                    :label="index + 1"
                    v-for="(item, index) in partnerCategory"
                    :key="index"
                    >{{ item.codeName }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="合作类型:" prop="">
                <el-radio-group
                  v-model="queryInfo.cooperationType"
                  @change="changeSearchangeSearch"
                >
                  <el-radio-button label="" :value="''">全部</el-radio-button>
                  <el-radio-button
                    :label="item.codeName"
                    v-for="(item, index) in cooperationTypes"
                    :key="index"
                    >{{ item.codeName }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="合作状态:" prop="">
                <el-radio-group
                  v-model="queryInfo.cooperationStatus"
                  @change="changeSearchangeSearch"
                >
                  <el-radio-button label="" :value="''">全部</el-radio-button>
                  <el-radio-button
                    :label="index"
                    v-for="(item, index) in cooperationState"
                    :key="index"
                    >{{ item.codeName }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label=" " label-width="0px" style="padding-top: 20px; margin-bottom: 0">
                <div class="input-with">
                  <div style="display: flex">
                    <el-button type="primary" @click="addparter()">新增</el-button>
                    <!-- <el-button plain >批量导出</el-button> -->
                  </div>
                  <div>
                    <el-input
                      placeholder="请输入合作伙伴名称"
                      v-model="queryInfo.queryConditions"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                        >搜索</el-button
                      >
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <span style="position: relative; top: -10px">当前条件下共查出{{ total }}家合作伙伴</span>
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div class="storeCenter_item_total">
            <div class="storeCenter_item_total_right">
              <el-button
                style="background: #f5f5f5; border: 0"
                size="mini"
                icon="el-icon-download"
                plain
                @click="exportExcel(item)"
                >导出</el-button
              >
            </div>
            <div class="item_checkbox">
              <el-checkbox
                @change="latechange($event, item)"
                v-model="item.checked1"
                label=""
              ></el-checkbox>
            </div>
            <div class="storeCenter_item_top">
              <div class="storeCenter_item_top_type">
                <span>{{ item.partnerType }}</span>
              </div>
              <div class="storeCenter_item_top_left">
                <img :src="item.coverImage" alt="" />
                <div class="storeCenter_item_top_left_flex">
                  <div style="display: flex; align-items: center">
                    <h5 style="display: inline-block; margin: 0; font-size: 18px">
                      {{ item.partnerName }}
                    </h5>
                  </div>
                  <div style="padding-top: 10px; text-decoration-line: underline; color: #4e93fb">
                    <span>{{ item.partnerWebsite }}</span>
                  </div>
                  <p class="address">
                    <span style="padding-left: 0px"> {{ item.partnerContactPerson }}</span>
                    <span> {{ item.concatPhone }}</span>
                    <span> {{ item.province }}-{{ item.city }}</span>
                  </p>
                </div>
              </div>
              <!-- <div class="storeCenter_item_top_content">
                <div style="margin: 0 20px">
                  <div class="storeCenter_item_top_content_title">服务数据</div>
                  <div class="storeCenter_item_top_content_data">{{ item.serviceNum }}</div>
                  <div class="storeCenter_item_top_content_day">较上周</div>
                  <div class="storeCenter_item_top_content_trend">
                    <div class="triangle"></div>
                    <div style="color: red">{{ item.serviceMonthNum }}</div>
                  </div>
                </div>
                <div style="margin: 0 20px">
                  <div class="storeCenter_item_top_content_title">用户数据</div>
                  <div class="storeCenter_item_top_content_data">{{ item.userNum }}</div>
                  <div class="storeCenter_item_top_content_day">较上周</div>
                  <div class="storeCenter_item_top_content_trend">
                    <div class="triangle"></div>
                    <div style="color: red">{{ item.userMonthNum }}</div>
                  </div>
                </div>
                <div style="margin: 0 20px">
                  <div class="storeCenter_item_top_content_title">客户数据</div>
                  <div class="storeCenter_item_top_content_data">{{ item.companyNum }}</div>
                  <div class="storeCenter_item_top_content_day">较上周</div>
                  <div class="storeCenter_item_top_content_trend">
                    <div class="triangle"></div>
                    <div style="color: red">{{ item.companyMonthNum }}</div>
                  </div>
                </div>
                <div style="margin: 0 20px">
                  <div class="storeCenter_item_top_content_title">诊断数据</div>
                  <div class="storeCenter_item_top_content_data">{{ item.diagnosisNum }}</div>
                  <div class="storeCenter_item_top_content_day">较上周</div>
                  <div class="storeCenter_item_top_content_trend">
                    <div class="triangle"></div>
                    <div style="color: red">{{ item.diagnosisMonthNum }}</div>
                  </div>
                </div>
                <div style="margin: 0 20px">
                  <div class="storeCenter_item_top_content_title">订单数量</div>
                  <div class="storeCenter_item_top_content_data">{{ item.orderNum }}</div>
                  <div class="storeCenter_item_top_content_day">较上周</div>
                  <div class="storeCenter_item_top_content_trend">
                    <div class="triangle"></div>
                    <div style="color: red">{{ item.orderMonthNum }}</div>
                  </div>
                </div>
              </div> -->
              <div class="storeCenter_item_top_right">
                <div class="operation_area">
                  <!-- <el-button type="primary" @click="toPage('rolechannel', item)"
                    >分配权限</el-button
                  >
                  <el-button type="info" plain @click="toPage('accountManagement', item)"
                    >账号管理</el-button
                  > -->
                  <el-button type="primary" @click="clciklabel(item)">关联企业（{{item.companyNum}}）</el-button>
                  <el-button type="primary">关联订单（{{item.orderNum}}）</el-button>
                  <span class="edit" @click="editparter(item)">编辑</span>
                  <!-- <span class="frozen" @click="delcompany(item)">冻结</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { listPage, exportPartnerExcel } from '@/api/channel'
import { getCode, getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  partnerType: '',
  city: '',
  orderByParam: null,
  pageNum: 1,
  pageSize: 10,
  province: '',
  district: '',
  directLeague: '', //加盟模式
  queryConditions: null,
  cooperationType: '',
  cooperationStatus: ''
})
export default {
  name: 'channel',

  data() {
    return {
      InformationTypes: [], //伙伴类型code
      partnerCategory: [], //伙伴类别code
      cooperationTypes: [], //合作类型code
      cooperationState: [], //合作状态code
      ids: [],
      idsList: [],
      queryInfo: { ...defaultQueryInfo }, //查询参数
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [] //区
    }
  },
  created() {
    // this.search()
    this.incity()
    this.getCode('012')
    this.getCode('029')
    this.getCode('030')
    this.getCode('031')
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //查询渠道类型code
    async getCode(data) {
      const query = {
        codeType: data
      }
      const res = await getCode(query)
      if (res.data.resultCode == 200) {
        if (data == '012') {
          this.InformationTypes = res.data.data
        }
        if (data == '029') {
          this.partnerCategory = res.data.data
        }
        if (data == '030') {
          this.cooperationTypes = res.data.data
        }
        if (data == '031') {
          this.cooperationState = res.data.data
        }
      }
    },
    //城市
    async incity() {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.queryInfo.pageNum = 1
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.pageNum = 1
              this.queryInfo.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.search()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },

    //账号管理
    toPage(type, row) {
      if (type == 'accountManagement') {
        this.$router.push({
          path: '/channel/account',
          query: {
            id: row.id
          }
        })
      } else if (type == 'rolechannel') {
        this.$router.push({
          path: '/channel/rolechannel',
          query: {
            id: row.id
          }
        })
      }
    },
    //关联企业
    clciklabel(row) {
      this.$router.push({
        name: 'relevance',
        query: {
          id: row.id
        }
      })
    },

    async search() {
      this.loading = true
      const res = await listPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        console.log(this.listData)
        this.total = res.data.data.total
        this.loading = false
      }
    },

    //编辑
    editparter(item) {
      this.$router.push({
        name: 'addpartner',
        query: {
          id: item.id,
          pageNum: this.queryInfo.pageNum
        }
      })
    },

    //新增合作伙伴资料
    addparter() {
      this.$router.push({
        name: 'addpartner'
      })
    },
    //关联用户
    UserClick(item) {
      this.$router.push({
        name: 'CorrelateUser',
        query: {
          id: item.id,
          secretaryGeneralName: item.secretaryGeneralName,
          chamberCommerceName: item.chamberCommerceName
        }
      })
    },
    //勾选合作伙伴
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      localStorage.setItem('paginationCurrentPage', val)
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    },
    // 导出
    async exportExcel(item) {
      const res = await exportPartnerExcel(item)
      // console.log("导出",res.data);
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `合作伙伴数据${nowtime}.xlsx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        delete this.queryInfo.ids
        this.search()
        // this.$refs.multipleTable.clearSelection()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.error('下载失败!')
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
::v-deep .el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .storeCenter_item_top_right .el-button {
  width: 150px;
}
.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.user {
  .container {
    height: 80vh;
    overflow: scroll;
  }
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .storeCenter_item_total {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .storeCenter_item_total_right {
        position: absolute; /* 设置为绝对定位 */
        top: 0; /* 距离顶部0px */
        right: 0; /* 距离右边0px */
        z-index: 1;
      }
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .storeCenter_item_top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        padding-bottom: 14px;
        position: relative;
        background: #ffffff;
        padding: 16px 20px 10px 10px;
        .storeCenter_item_top_type {
          position: absolute;
          width: 110px;
          height: 34px;
          background: #ff7d18;
          color: #fff;
          text-align: center;
          line-height: 34px;
          border-radius: 10px 10px 10px 0px;
          top: 0px;
          left: 0px;
        }
        .storeCenter_item_top_left {
          display: flex;
          flex-direction: row;

          img {
            width: 140px;
            height: 88px;
            border-radius: 2px;
            margin-right: 14px;
          }

          .storeCenter_item_top_left_flex {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .img_commer {
              display: flex;
              border: 1px solid #146aff;
              border-radius: 39px;
              .name {
                font-size: 12px;
                line-height: 20px;
                color: #146aff;
              }
              .color_img {
                background: #146aff;
                height: 20px;
                width: 25px;
                border-radius: 20px;
                position: relative;
                left: -10px;
                img {
                  width: 13px;
                  height: 13px;
                  position: relative;
                  left: 5px;
                  top: 2px;
                }
              }
            }
            .img_commers {
              display: flex;
              border: 1px solid #ff9c00;
              border-radius: 39px;
              margin: 0px 10px;
              .name {
                font-size: 12px;
                line-height: 20px;
                color: #ff9c00;
              }
              .color_img {
                background: #ff9c00;
                height: 20px;
                width: 25px;
                border-radius: 20px;
                position: relative;
                left: -10px;
                img {
                  width: 13px;
                  height: 13px;
                  position: relative;
                  left: 5px;
                  top: 2px;
                }
              }
            }

            h4 {
              color: #1f2d3d;
              font-size: 26px;
              font-weight: 500;
              margin: 0;
            }

            .address {
              font-size: 14px;
              span {
                padding: 0px 10px;
              }
            }
          }
        }
        .storeCenter_item_top_right {
          align-items: stretch;
          height: 100%;
          .account_cycle {
            text-align: right;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            /* 灰色字体 */

            color: #969495;
          }
          .operation_area {
            margin-top: 30px;
            .buttom_right {
              height: 36px;
              line-height: 1px;
            }
            .edit {
              padding-left: 20px;
              color: #4e93fb;
              cursor: pointer;
            }
            .frozen {
              padding-left: 20px;
              color: #fd5469;
              cursor: pointer;
            }
          }
        }
        .storeCenter_item_top_content {
          margin: auto;
          display: flex;
          justify-content: space-evenly;
          .storeCenter_item_top_content_title {
            font-size: 16px;
            font-weight: 500;
            margin: 5px 0;
          }
          .storeCenter_item_top_content_data {
            font-size: 16px;
            font-weight: 600;
            margin: 5px auto;
          }
          .storeCenter_item_top_content_day {
            font-size: 14px;
            color: #a3a6ac;
            margin: 5px 0;
          }
          .storeCenter_item_top_content_trend {
            display: flex;
            // justify-content: space-between;
            // padding: 0 10px; /* 容器两边各留 20px */
            margin: 5px 0;
            .triangle {
              width: 0;
              height: 0;
              margin: auto 5px;
              // margin-left: -15px; /* 可选，如果三角形因为左边框向左偏移了，则调整回来 */
              border-left: 5px solid transparent; /* 左边框透明 */
              border-right: 5px solid transparent; /* 右边框透明 */
              border-bottom: 5px solid red; /* 下边框为红色 */
            }
          }
        }
      }
    }

    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
  }
}
</style>
